/*
 *
 *  Moon
 *
 *  Designer: Taylan Tatlı
 *  WEB: http://taylantatli.me
 *
*/

// Partials
@import "variables";
@import "mixins";
@import "normalize";
@import "site";
@import "typography";
@import "elements";
@import "syntax";
@import "nav";
@import "vendor/fonts";
@import "vendor/font-awesome";
@import "vendor/animate";
@import "vendor/magnific-popup/magnific-popup";
@import "print";

@font-face {
    font-family: "academicons";
    src: url("../assets/fonts/academicons-1.8.6/fonts/academicons.ttf");
}

h1 {
    text-align: center
}

.credits {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    color: white;
    text-align: center;
    //background: rgba(192, 192, 192, 0.2);

    @media #{$small} {
        visibility: hidden;
    }
    //@media #{$medium} {
    //    visibility: hidden;
    //}
}

.wrapper{
    // Gives space for the credits container at the bottom (even in tablet mode)
    margin-bottom: 150px;
}
